import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetRefreshToken } from 'app/models/interfaces/refreshToken';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthTokenInterceptor implements HttpInterceptor {
  urlRefresh = '/api/user/token/refresh';
  url = environment.url.protocol + environment.url.url + this.urlRefresh;
  urlGoogleCalendar = 'https://www.googleapis.com/calendar/v3/calendars/';
  urlYa = '/o/oauth2/iframerpc?action=issueToken';
  oldAuthToken = '';
  oldIdToken = '';

  constructor(private http: HttpClient, private auth: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('userIdToken');
    if (!req.url.includes(this.urlGoogleCalendar)) {
      if (
        token &&
        !req.url.includes(this.urlRefresh) &&
        this.auth.isAuthenticated()
      ) {
        const headers = new HttpHeaders({ Authorization: token });
        req = req.clone({
          headers,
        });
      }
    }

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          localStorage.removeItem('userIdToken');
          const refresh = `Bearer ${localStorage.getItem(
            'userIdRefreshToken'
          )!}`;
          const headersRefresh = new HttpHeaders({
            Authorization: refresh,
          });
          return this.handle(req, next, headersRefresh);
        } else {
          return throwError(err);
        }
      })
    );
  }

  async handle(
    req: HttpRequest<any>,
    next: HttpHandler,
    header: HttpHeaders
  ): Promise<HttpEvent<any>> {
    try {
      const { appToken, refreshToken } = await this.http
        .get<GetRefreshToken>(this.url, { headers: header })
        .toPromise();
      localStorage.setItem('userIdToken', 'Bearer ' + appToken);
      localStorage.setItem('userIdRefreshToken', 'Bearer ' + refreshToken);
      const newHeader = new HttpHeaders({
        Authorization: `Bearer ${appToken}`,
      });
      req = req.clone({
        headers: newHeader,
      });
    } catch (error) {
      console.log(error);
    }

    return next.handle(req).toPromise();
  }
}
