import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
  HttpClient,
} from '@angular/common/http';
import { CalendarService } from '../calendar.service';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { SocialAuthService, GoogleLoginProvider } from 'angularx-social-login';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { UserToken } from 'app/models/interfaces/userToken.interface';

@Injectable()
export class CalendarInterceptor implements HttpInterceptor {
  urlGoogleCalendar = 'https://www.googleapis.com/calendar/v3/calendars/';
  urlRefresh = '/api/user/token/refresh';
  url = environment.url.protocol + environment.url.url + this.urlRefresh;
  urlYa = '/o/oauth2/iframerpc?action=issueToken';
  oldAuthToken = '';
  oldIdToken = '';

  constructor(
    private calendarService: CalendarService,
    private authService: SocialAuthService,
    private auth: AuthService,
    private http: HttpClient
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    req = this._headerIntercept(req);
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        this._handle401CalendarError(req, err);
        return throwError(err);
      })
    );
  }

  private _handle401CalendarError(
    req: HttpRequest<unknown>,
    err: HttpErrorResponse
  ): Observable<never> | void {
    if (
      err.status === 401 &&
      req.url.includes(this.calendarService.getCalendarUrl())
    ) {
      this._refresh(req);
      this.auth.userGoogle();
      this._saveNewUser();
    } else {
      return throwError(err);
    }
  }

  private _refresh(req: HttpRequest<unknown>): void {
    this._obtainOldTokens();
    this.auth
      .getAppToken(this.oldIdToken, this.oldAuthToken)
      .then(() => this._refreshAuthToken(req))
      .catch(err => console.log(err));
  }

  private _headerIntercept(req: HttpRequest<unknown>) {
    if (req.url.includes(this.calendarService.getCalendarUrl())) {
      return req.clone({ headers: this._addHeader() });
    }
    return req;
  }

  private _addHeader(): HttpHeaders {
    const user = JSON.parse(localStorage.getItem('user')!);
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + user.authToken,
    });
  }

  private _obtainOldTokens() {
    const oldUser = JSON.parse(localStorage.getItem('user')!);
    this.oldAuthToken = oldUser.authToken;
    this.oldIdToken = oldUser.idToken;
  }

  private _refreshAuthToken(req: HttpRequest<unknown>): void {
    this.authService
      .refreshAuthToken(GoogleLoginProvider.PROVIDER_ID)
      .catch(() => {
        this._handleRefreshError(req);
      });
  }

  private _handleRefreshError(req: HttpRequest<unknown>): void {
    this.auth.login().then((userData: UserToken) => {
      this._resendRequest(req, userData);
    });
  }

  private _resendRequest(req: HttpRequest<unknown>, userData: UserToken): void {
    const newUser = JSON.parse(localStorage.getItem('user')!);
    newUser.authToken = userData.authToken;
    newUser.idToken = userData.idToken;
    localStorage.setItem('user', newUser);
    req = this._headerIntercept(req);
    this.http.post(req.url, req.body, { headers: req.headers });
  }

  private _saveNewUser(): void {
    const user = localStorage.getItem('user');
    if (user) {
      const newUser = JSON.parse(user);
      const idToken = newUser.idToken;
      localStorage.setItem('userIdToken', `${idToken}`);
      localStorage.setItem('userIdRefreshToken', `${idToken}`);
    }
  }
}
