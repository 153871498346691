import { generalConfig } from './generalConfig';

const url = {
    protocol: 'https://',
    url: 'dev.backend.dashboard.talento.mobydigital.com',
};

export const environment = {
    url,
    ...generalConfig,
    production: false,
};
