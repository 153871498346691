import { createSelector } from '@ngrx/store';
import { SendNotificationState } from '../model/sendNotification.state';
import { AppNotificationState } from '../../../../shared/components/notifications/state/notifications.state';

export const selectSendNotificationFeature = (state: AppNotificationState) =>
  state.notificationSend;

export const selectListNotification = createSelector(
  selectSendNotificationFeature,
  (state: SendNotificationState) => state.notification
);
