import { Injectable } from '@angular/core';
import { Svg } from 'app/models/svg/svg.model';
import { CandidateDto } from 'app/models/interfaces/candidateDto.interface';
import { Experience } from 'app/models/interfaces/experience.interface';
import { TechnologyByCandidate } from 'app/models/interfaces/technologyByCandidate.interface';
import { StudyDto } from 'app/models/interfaces/studyDto.interface';
import { DateTimeService } from './date-time.service';

@Injectable({
  providedIn: 'root',
})
export class CreatePdfService {
  circleSvg!: Svg;
  emailSvg!: Svg;
  mobyLogoSvg!: Svg;
  companySvg!: Svg;
  educationSvg!: Svg;
  linkedinSvg!: Svg;

  constructor(private dateTimeService: DateTimeService) {
    this.circleSvg = new Svg(
      'circle',
      '148',
      '74',
      'position: absolute; bottom: -5.5em; right: -6em',
      ''
    );
    this.emailSvg = new Svg('email', '21', '21', '', '');
    this.mobyLogoSvg = new Svg(
      'mobyLogo',
      '158',
      '25',
      'position: absolute; right: 0',
      ''
    );
    this.companySvg = new Svg('company', '21', '21', '', '');
    this.educationSvg = new Svg('education', '21', '21', '', '');
    this.linkedinSvg = new Svg('linkedin', '21', '16', '', '');
  }

  timeInTheJob(startDate: string, endDate: string | Date | null): string {
    return this.dateTimeService
      .yearsDifferenceBetweenDates(startDate, endDate)
      .replace('s ', 's, ');
  }

  createPdf(data: CandidateDto): string {
    const contact = { ...data };

    return `
      <html>
        <head>
          <meta charset="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta http-equiv="X-UA-Compatible" content="ie=edge" />
          <link
            href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap"
            rel="stylesheet"
          />
          <title>Pdf ${contact.name}</title>
          ${this.styles()}
        </head>
        <body class="body">
          ${this.header(contact)}
          ${this.main(contact)}
          ${this.circleSvg.generateSvg()}
        </body>
      </html>`;
  }

  header(contact: CandidateDto): string {
    return `
      <header>
        ${this.mobyLogoSvg.generateSvg()}
        <article class="containerHeader">
          <img src=${
            contact.photo
          } style="position: absolute; top: -3em; left: 1.5em; width: 90px; heigth :90px; border-radius:50%;">
          <section class="containerHeaderSection containerHeaderSectionOne">
            <h1 class="textHeaderSection darkPrimaryBlue textCapitalize" 
                style="font-size: 18px; margin-bottom: 10px" >
              ${contact.name}
              ${contact.lastName}
              ${
                contact.lastExperience
                  ? ' - ' + contact.lastExperience?.seniority!.description
                  : ''
              }
            </h1>
            ${this._printLastExperienceParagraph(contact)}
            ${this._printLocationParagraph(contact)}
          </section>
          <section class="containerHeaderSection containerHeaderSectionTwo">
            ${this._printLastExperienceDivisor(contact)}
            ${this._printStudiesDivisor(contact)}
            ${this._printContacByLinkedIn(contact)}
            ${this._printContacByEmail(contact)}
          </section>
        </article>
      </header>
    `;
  }

  _printLastExperienceParagraph(contact: CandidateDto) {
    return contact.lastExperience
      ? ` <p class="textHeaderSection darkPrimaryBlue textCapitalize"
             style="font-size: 13px; margin-bottom: 10px" >
              ${contact.lastExperience.jobPosition} en ${contact.lastExperience.company}
          </p>`
      : '';
  }

  _printLocationParagraph(contact: CandidateDto) {
    return contact.locationOfCandidate.locality.name ||
      contact.locationOfCandidate.locality.province!.name
      ? ` <p class="textHeaderSection grayOne textCapitalize" style="font-size: 13px">
                  ${contact.locationOfCandidate.locality.name + ','}
                  ${contact.locationOfCandidate.locality.province!.name}
          </p> `
      : '';
  }

  _printLastExperienceDivisor(contact: CandidateDto) {
    return contact.lastExperience
      ? `<div class="containerpPersonalInformation">
            ${this.companySvg.generateSvg()}
            <p class="textCapitalize textHeaderSection darkPrimaryBlue textHeaderInformation">
              ${contact.lastExperience.company}
            </p>
        </div> `
      : '';
  }

  _printStudiesDivisor(contact: CandidateDto) {
    return contact.studies.length > 0
      ? `<div class="containerpPersonalInformation">
            ${this.educationSvg.generateSvg()}
            <p class="textHeaderSection darkPrimaryBlue textHeaderInformation textCapitalize">
              ${this.getLastStudy(contact).degree}
            </p>
         </div> `
      : '';
  }

  getLastStudy(contact: CandidateDto) {
    let lastDate: Date = new Date(contact.studies[0].endDate);
    let lastStudy: StudyDto;
    contact.studies.forEach(study => {
      lastDate <= new Date(study.endDate) &&
        (lastDate = new Date(study.endDate));
    });

    lastStudy = contact.studies.find(
      study => new Date(study.endDate).getTime() == lastDate.getTime()
    )!;
    return lastStudy;
  }

  _printContacByLinkedIn(contact: CandidateDto) {
    return contact.linkedin
      ? `<div class="containerpPersonalInformation">
            ${this.linkedinSvg.generateSvg()}
            <p class="textHeaderSection darkPrimaryBlue textHeaderInformation">
              ${contact.linkedin}
            </p>
        </div> `
      : ``;
  }

  _printContacByEmail(contact: CandidateDto) {
    return contact.email
      ? ` <div style="display: flex">
            ${this.emailSvg.generateSvg()}
            <p class="textHeaderSection darkPrimaryBlue textHeaderInformation">
              ${contact.email}
            </p>
          </div> `
      : '';
  }

  main(contact: CandidateDto): string {
    const regexPoint = /,[\n]/g;
    return `
      <main class="main">
        ${this.profile(contact)}
        ${this.experience(contact).replace(regexPoint, ' ')}
        ${this.education(contact).replace(regexPoint, ' ')}
        ${this.skills(contact).replace(regexPoint, ' ')}
      </main>`;
  }

  profile(contact: CandidateDto): string {
    return `
      <section class="mainSections">
        <div class="titleSectionContainer">
          <h4 class="textContainer">Perfil</h4>
        </div>
        ${this._printDecriptionDivison(contact)}
      </section>`;
  }

  _printDecriptionDivison(contact: CandidateDto) {
    return contact.description
      ? `<div class="containerInformationSection">
            <p class="darkPrimaryBlue textProfile" >${contact.description}</p>
        </div> `
      : '';
  }

  experience(contact: CandidateDto): string {
    return `
    <section class="mainSections">
      <div class="titleSectionContainer">
        <h4 class="textContainer">Experiencia</h4>
      </div>
      ${
        contact.experiences.length > 0
          ? contact.experiences.map((_experience: Experience) => {
              return this.experienceList(_experience);
            })
          : ''
      }
    </section>`;
  }

  experienceList(experience: Experience): string {
    let date;
    if (!this.timeInTheJob(experience.startDate, experience.endDate)) {
      date = 'Actualidad';
    } else {
      date = this.timeInTheJob(experience.startDate, experience.endDate);
    }
    return `
          <div class="containerInformationSection">
            <div class="maxWidthExperience">
              <span class="containerExperienceJob">
                <p class="textJobPosition">
                  ${experience.jobPosition}
                </p>
                <p class="timeInTheJob">${date}</p>
              </span>
              <span class="containerExperienceCompany">
                <p class="textCompany">
                ${experience.company}
                </p>
                <p class="textScriptExperiences">-</p>
                <p class="textWorkingDay">Jornada Completa</p>
              </span>
            </div>
            <span class="textLocation">${experience.companyLocation}</span>
          </div> `;
  }

  education(contact: CandidateDto): string {
    return `
        <section class="mainSections">
          <div class="titleSectionContainer">
            <h4 class="textContainer">Educación</h4>
          </div>
          ${
            contact.studies.length > 0
              ? contact.studies.map((_study: StudyDto) => {
                  return this.educationList(_study);
                })
              : ''
          }
        </section>`;
  }

  educationList(study: StudyDto): string {
    return `
      <div class="containerInformationSection">
        <div class="maxWidthEducation">
          <span class="containerEducationCareer">
            <p class="textCareer" >
              ${study.degree}
            </p>
            <p class="textStartDate">${study.startDate
              .toString()
              .slice(0, 4)}</p>
            ${this._printStudyEndDateParagraph(study)}
          </span>
          <span class="containerEducationInstitution">
            <p class="textInstitution">
            ${study.institutionName}
            </p>
            <p class="script">-</p>
            <p class="textState">
              ${study.state}
            </p>
          </span>
        </div>
        <span class="textLocation">Mar del Plata, Buenos Aires</span>
      </div>`;
  }

  _printStudyEndDateParagraph(study: StudyDto) {
    return study.endDate
      ? `<p class="textEndDate">/ ${study.endDate.toString().slice(0, 4)}</p>`
      : '';
  }

  skills(contact: CandidateDto): string {
    return `
    <section class="mainSections">
      <div class="titleSectionContainer">
        <h4 class="textContainer">Skills</h4>
      </div>
      <div class="containerChips">
        ${
          contact.candidateTechnologies.length > 0
            ? contact.candidateTechnologies.map(
                (_technologies: TechnologyByCandidate) => {
                  return this.skillsList(_technologies);
                }
              )
            : ''
        }
      </div>
    </section>`;
  }

  skillsList(technologies: TechnologyByCandidate): string {
    return `
      <span class="chips">${technologies.technology.name}</span>
    `;
  }

  styles(): string {
    return `
      <style>
        * {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
          font-family: "Inter", sans-serif;
        }
        .body {
          overflow-x: hidden;
          background-color: #f2f2f2;
          width: 100vw;
          min-height: 100vh;
          padding: 40px;
          display: flex;
        }
        .textCapitalize {
          text-transform: capitalize;
        }
        ${this.headerStyle()}
        ${this.mainStyle()}
        .darkPrimaryBlue {
          color: #010318;
        }
        .grayOne {
          color: var(--secondary-text);
        }
      </style>`;
  }

  headerStyle(): string {
    return `
    .containerHeader {
      width: 100%;
      margin-top: 40px;
      padding: 0 10px 0 20px;
      min-height: 180px;
      background-color: #ffffff;
      border-radius: 10px;
      position: relative;
    }
    .containerHeaderSection {
      display: inline-block;
    }
    .containerHeaderSectionOne {
      padding-top: 60px;
      margin-right: 30px;
    }
    .containerHeaderSectionTwo {
      padding-top: 25px;
    }
    .textHeaderSection {
      word-wrap: break-word;

      width: 260px;
      overflow: hidden;
      display: block;
    }
    .textHeaderInformation {
      font-size: 13px;
      margin-left: 5px;
      padding-top: 2px;
    }
    .containerpPersonalInformation {
      display: flex;
      margin-bottom: 20px;
    }`;
  }

  mainStyle(): string {
    return `
    ${this.educationStyles()}
    ${this.experiencesStyles()}
    ${this.skillsStyles()}
    .containerEducation{
      width: 100%;
      height: 40px;
      padding-left: 50px;
      margin: 30px auto 0 auto;
      display: flex;
    }
    .main{
      display: block;
      margin-top: 40px;
    }
    .mainSections{
      width: 100%; 
      min-height: 80px; 
      margin-bottom: 10px;
    }
    .textLocation {
      position: absolute;
      right:0;
      color: var(--secondary-text);
      display: inline-block;
      width: 130px;
      font-size: 13px;
      text-align: center;
    }
    .titleSectionContainer {
      width: 100%;
      background-color: #010318;
      border-radius: 5px;
      height: 35px;
      display: flex;
      align-items: center;
      padding-left: 50px;
      margin-top: 20px;
    }
    .textContainer {
      color: #fefefe;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.5px;
    }
    .containerInformationSection {
      position: relative;
      width: 100%;
      min-height: 40px;
      padding-left: 50px;
      margin: 30px auto 0 auto;
      display: flex;
    }
    .maxWidthEducation{
      max-width: 390px;
    }
    .maxWidthExperience{
      max-width: 390px;
    }`;
  }

  educationStyles(): string {
    return `
    .containerEducationCareer{
      display: flex;
      margin-bottom: 10px;
    }
    .textCareer{
      font-size: 13px;
      font-weight: 600;
      margin-right: 15px;
      color: #010318;
    }
    .textStartDate{
      font-size: 13px;
      color: var(--secondary-text); 
      margin-right: 5px;
    }
    .textEndDate{
      font-size: 13px; 
      color: var(--secondary-text);
    }
    .containerEducationInstitution{
      display: flex;
      width: 100%;
    }
    .textInstitution{
      color: #010318;
      display: block;
      font-size: 13px;
      width: max-content;

    }
    .textState{
      font-size: 13px;
      font-weight: 600;
        color: #010318;
    }
    .script{
      margin: 0 5px;
      font-size: 13px;
    }
    .textProfile{
      font-size: 13px;
    }`;
  }

  experiencesStyles(): string {
    return `
    .containerExperienceJob{
      display: flex;
    }
    .textJobPosition{
      margin-right: 10px;
      font-weight: 600;
      color: #010318;
      font-size: 13px;
    }
    .timeInTheJob{
      color: var(--secondary-text);
      font-size: 13px
    }
    .containerExperienceCompany{
      display: flex;
      margin-top: 10px;
      max-width: 100%;
      word-wrap: break-word;
    }
    .textCompany{
      width: max-content;
      font-weight: 500;
      color: #010318;
      font-size: 13px;
      display:block; 
      word-wrap: break-word;
    }
    .textWorkingDay{
      color: #010318;
      font-size: 13px;
      display:block;
      width: 140px;
      word-wrap: break-word;
    }
    .textScriptExperiences{
      margin: 0 5px;
      font-size: 13px
    }`;
  }

  skillsStyles(): string {
    return `
    .containerChips {
      width: 90%;
      min-height: 30px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-gap: 10px;
    }
    .chips {
      padding: 4px 20px;
      margin: 15px 5px 10px 0;
      background-color: #ededed;
      border: 1px solid #d1d1d1;
      border-radius: 5px;
      font-size: 13px;
      color: #010318;
      display: inline-block;
    }`;
  }
}
