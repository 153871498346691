import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { DashboardService } from './dashboard.service';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { ContactCardComponent } from 'app/main/contacts/contact-card/candidate-card.component';
import { DeleteCandidateMatDialogComponent } from 'app/main/dashboard/opportunity/person/delete-candidate-mat-dialog/delete-candidate-mat-dialog.component';

import { ICreateCandidateDto } from 'app/models/createCandidateDto.model';
import { ActivateStage } from 'app/models/interfaces/activeStageByCandidate.interface';
import { CandidateByVacantDto } from 'app/models/interfaces/candidateByVacantDto.interface';
import {
  CandidateDto,
  CandidateUpdateDto,
} from 'app/models/interfaces/candidateDto.interface';
import { OpportunityDto } from 'app/models/interfaces/createOpportunityDto.interface';
import {
  AddNewExperienceInterface,
  EducationScore,
  Experience,
  ExperienceScore,
} from 'app/models/interfaces/experience.interface';
import { FilterCandidateDto } from 'app/models/interfaces/filterCandidateDto.interface';
import { LocalityApiDto } from 'app/models/interfaces/localityApiDto.interface';
import { ProvinceApiDto } from 'app/models/interfaces/provinceApiDto.interface';
import {
  Recruiter,
  RecruiterInfo,
} from 'app/models/interfaces/recruiter.interface';
import { ScrappingResponse } from 'app/models/interfaces/scrapping.interface';
import { StageCandidateDto } from 'app/models/interfaces/stage.interface';
import { StudyDto } from 'app/models/interfaces/studyDto.interface';
import { Pagination } from 'app/models/pagination.model';
import { environment } from '../../environments/environment';
import {
  CandidateData,
  CandidateID,
  IemitFiles,
} from '../models/interfaces/createCandidate.interface';
import { TechnologyDto } from './../models/interfaces/technologyDto.interface';
import {
  CognitiveScore,
  MentalityScore,
} from 'app/models/interfaces/informationScores.interface';
import { RadarScores, TypeOfRadar } from 'app/models/interfaces/radar.interface';

@Injectable()
export class CandidatesService {
  technologies!: TechnologyDto[];
  @Output() issueAggregates = new EventEmitter<string[]>();
  opportunity!: OpportunityDto;
  filter!: BehaviorSubject<FilterCandidateDto>;
  filterPagination!: BehaviorSubject<Pagination>;
  quantityCandidates!: BehaviorSubject<number>;
  candidatesDto!: Subject<CandidateDto[]>;
  candidateData: CandidateData | null = null;
  files: IemitFiles | null = null;
  private radarScores = new BehaviorSubject<RadarScores>({
    experience: 1,
    mentality: 0,
    education: 1,
    cognitive: 0,
    knowledge: 0,
  });

  stagesNames = [
    'Busqueda',
    'Pre selección',
    'Entrevista',
    'Evaluación',
    'Presentación',
    'Integración',
  ];

  // Base URL
  private baseUrl: string = environment.url.protocol + environment.url.url;

  // URL
  urlupdateStage: string =
    this.baseUrl + environment.services.candidates.updateStage;
  urlGetProfiles: string =
    this.baseUrl + environment.services.candidates.getProfiles;
  urlGetSeniorities: string =
    this.baseUrl + environment.services.dashboard.getSeniorities;
  urlCheckDNI: string =
    this.baseUrl + environment.services.candidates.getDniExiste;
  urlPostCandidate: string =
    this.baseUrl + environment.services.candidates.postNewCandidate;
  urlDeleteCandidate: string =
    this.baseUrl + environment.services.candidates.deleteCandidate;
  urlGetCandidateByVacant =
    this.baseUrl + environment.services.candidates.getCandidateByVacant;
  urlGetListRecruiters =
    this.baseUrl + environment.services.candidates.getListRecruiters;
  getCheckEmail: string =
    this.baseUrl + environment.services.candidates.getEmailIsCreated;
  urlGetCandidateById: string =
    this.baseUrl + environment.services.candidates.getCandidateById;
  urlGetCandidateByLinkedin: string =
    this.baseUrl + environment.services.candidates.getCandidateByLinkedin;
  urlGetInfoById: string =
    this.baseUrl + environment.services.candidates.getCandidateById;
  urlGetActiveProcesses: string =
    this.baseUrl + environment.services.opportunity.getActiveProcesses;
  private urlGetCandidatesByVacant: string =
    this.baseUrl + environment.services.candidates.getCandidatesByVacant;
  private urlGetLinkedinExists: string =
    this.baseUrl + environment.services.candidates.getLinkedinExists;
  private urlGetCandidateByVacantByIds: string =
    this.baseUrl + environment.services.candidates.getCandidateByVacantByIds;
  private urlGetFollowers: string =
    this.baseUrl + environment.services.candidates.getFollowers;
  private urlGetActiveStageByCandidate: string =
    this.baseUrl + environment.services.candidates.getActivateStageByCandidate;
  private urlEditCandidate: string =
    this.baseUrl + environment.services.candidates.editCandidate;
  private findAllWorkExperiencesPositions: string =
    this.baseUrl +
    environment.services.candidates.findAllWorkExperiencesPositions;

  //agregar experiencias
  private urlPostExperience: string =
    this.baseUrl + environment.services.candidates.addExperience;
  private urlPostStudies: string =
    this.baseUrl + environment.services.candidates.addStudy;
  private urlUploadCandidateByLinekdin: string =
    this.baseUrl +
    environment.services.candidates.getUploadCandidateByLinkedinUrl;
  private urlGetExperienceScore: string =
    this.baseUrl + environment.services.candidates.getExperienceScore;
  private urlGetEducationScore: string =
    this.baseUrl + environment.services.candidates.getEducationScore;
  private urlUploadExperienceScore: string =
    this.baseUrl + environment.services.candidates.uploadExperienceScore;
  private urlUploadEducationScore: string =
    this.baseUrl + environment.services.candidates.uploadEducationScore;

  private urlUpdateScoreKnowledge: string =
    this.baseUrl + environment.services.candidates.updateScoreKnowledge;
  private urlDeleteFollower: string =
    this.baseUrl + environment.services.candidates.deleteFollower;

  // Recruiter Candidate
  private urlGetRecruiter: string =
    this.baseUrl + environment.services.candidates.getRecruiter;
  private urlFindCognitiveAbilitiesScore: string =
    this.baseUrl + environment.services.candidates.findCognitiveAbilitiesScore;
  private urlCognitiveAbilitiesScore: string =
    this.baseUrl + environment.services.candidates.cognitiveAbilitiesScore;
  private urlMentalityAbilitiesScore: string =
    this.baseUrl + environment.services.candidates.updateMentalityScore;
  private urlGetMentalityAbilitiesScore: string =
    this.baseUrl + environment.services.candidates.getMentalityScore;

  //Cancel processes
  private urlAbortScraping: string =
    this.baseUrl + environment.services.candidates.abortScraping;

  //new Candidate endpoint
  private urlGetXCandidates: string =
    this.baseUrl + environment.services.candidates.getXCandidates;

  private currentOrder: string = 'NAMEA_Z';

  constructor(
    private httpClient: HttpClient,
    private dashboardService: DashboardService,
    private _matDialog: MatDialog
  ) {
    this.quantityCandidates = new BehaviorSubject(10);
    this.filterPagination = new BehaviorSubject(new Pagination(10, 0));
    this.candidatesDto = new Subject<CandidateDto[]>();
  }

  public setOrdering(order: string) {
    this.currentOrder = order;
  }

  getCandidatesFilter(
    filterObject: FilterCandidateDto,
    data: { size: number; page: number }
  ) {
    const params = new HttpParams()
      .set('size', data.size)
      .set('page', data.page)
      .set('order', this.currentOrder);
    return this.httpClient
      .post<CandidateDto[]>(this.urlGetXCandidates, filterObject, {
        observe: 'response',
        params,
      })
      .pipe(takeUntil(this.candidatesDto));
  }

  addStudyToCandidateInfo(
    study: StudyDto,
    candidateId: number
  ): Observable<StudyDto> {
    const url = this.urlPostStudies + candidateId;
    return this.httpClient.post<StudyDto>(url, study);
  }

  addExperienceToCandidateInfo(
    experience: AddNewExperienceInterface
  ): Observable<Experience> {
    const url = this.urlPostExperience;
    return this.httpClient.post<Experience>(url, experience);
  }

  getExperienceScore(candidateId: number): Observable<number> {
    const url = `${this.urlGetExperienceScore}/${candidateId}`;
    return this.httpClient
      .get<number>(url)
      .pipe(
        tap((res) =>
          this.updateRadarScore('experience', res)
        )
      );
  }

  updateExperienceScore(experienceScore: ExperienceScore) {
    this.updateRadarScore('experience', experienceScore.experienceScore);
    return this.httpClient.put(this.urlUploadExperienceScore, experienceScore);
  }

  getEducationScore(candidateId: number): Observable<EducationScore> {
    return this.httpClient
      .get<EducationScore>(this.urlGetEducationScore + candidateId)
      .pipe(
        tap(({ educationScore }) =>
          this.updateRadarScore('education', educationScore )
        )
      );
  }

  getActiveStageByCandidate(candidateId: number): Observable<ActivateStage[]> {
    return this.httpClient.get<ActivateStage[]>(
      this.urlGetActiveStageByCandidate + candidateId
    );
  }

  updateEducationScore(educationScore: EducationScore) {
    this.updateRadarScore('education', educationScore.educationScore);
    return this.httpClient.put(this.urlUploadEducationScore, educationScore);
  }

  createCandidate(data: ICreateCandidateDto): Observable<CandidateID> {
    return this.httpClient.post<CandidateID>(this.urlPostCandidate, data);
  }

  deleteCandidate(candidate: CandidateDto): Observable<CandidateDto> {
    const candidateId = candidate.id;
    const url = this.urlDeleteCandidate + candidateId;
    return this.httpClient.delete<CandidateDto>(url, {});
  }

  getRecruiter(candidateId: number): Observable<RecruiterInfo> {
    const url = this.urlGetRecruiter + candidateId;
    return this.httpClient.get<RecruiterInfo>(url);
  }

  openFile(fileUrl: string) {
    return this.httpClient.get(fileUrl, {
      headers: new HttpHeaders({
        Accept: 'application/pdf',
      }),
      responseType: 'blob',
    });
  }

  checkCandidateDNI(dni: string): Observable<boolean> {
    if (dni == '') {
      dni = '1';
    }
    const url = this.urlCheckDNI + dni;
    return this.httpClient.get<boolean>(url);
  }

  getCandidateByVacant(
    idCandidate: number,
    idVacant: number
  ): Observable<CandidateDto> {
    const url = this.urlGetCandidateByVacant + idCandidate + '/' + idVacant;
    return this.httpClient.get<CandidateDto>(url);
  }

  getCandidateById(id: number): Observable<CandidateDto> {
    const url = this.urlGetCandidateById + id;
    return this.httpClient.get<CandidateDto>(url);
  }

  getByLinkedin(linkedin: string): Observable<CandidateDto> {
    const res = linkedin.replace('https://', '');
    const url = this.urlGetCandidateByLinkedin + res;
    return this.httpClient.get<CandidateDto>(url);
  }

  getActiveProcesses(id: number): Observable<number> {
    const url = this.urlGetActiveProcesses + id;
    return this.httpClient.get<number>(url);
  }

  updateStage(
    candidateId: number,
    stageId: number,
    vacantId: number
  ): Observable<StageCandidateDto> {
    const url = this.urlupdateStage + `${vacantId}`;
    const body = {
      candidateId: candidateId,
      stageId: stageId,
    };

    return this.httpClient.post<StageCandidateDto>(url, body);
  }

  normalizeString(str: string): string {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }

  filterArrayByName(
    array: Array<ProvinceApiDto | LocalityApiDto>,
    nameToSearch: string
  ): Array<ProvinceApiDto | LocalityApiDto> {
    return array.filter(option =>
      this.normalizeString(option.name).includes(nameToSearch)
    );
  }

  getCandidatesByVacant(vacantID: number): Observable<CandidateDto[]> {
    const url = this.urlGetCandidatesByVacant + vacantID;
    return this.httpClient.get<CandidateDto[]>(url);
  }

  checkLinkedin(linkedin: string): Observable<boolean> {
    const url = this.urlGetLinkedinExists + linkedin;
    return this.httpClient.get<boolean>(url);
  }

  getListRecruiters(): Observable<Recruiter[]> {
    const url = this.urlGetListRecruiters;
    return this.httpClient
      .get<Recruiter[]>(url)
      .pipe(map(res => res.map((item: Recruiter) => item)));
  }

  endCandidateProcess(
    dialogRef: MatDialogRef<DeleteCandidateMatDialogComponent>,
    candidateID: number,
    vacantID: number,
    candidateFilter?: FilterCandidateDto
  ): Observable<CandidateDto> {
    return new Observable<CandidateDto>(observer => {
      dialogRef.componentInstance.messageEvent.subscribe(result => {
        this.deleteCandidate(result);
        observer.next(result);
      });

      dialogRef.afterClosed().subscribe(result => {
        const motivo = result;
        if (result) {
          this.dashboardService
            .historyDeleteCandidate(candidateID, vacantID, motivo)
            .subscribe({
              next: () => {
                this.dashboardService
                  .deleteCandidateFromOportunity(candidateID, vacantID)
                  .subscribe(() => {
                    if (candidateFilter) {
                      this.filter.next(candidateFilter);
                    }
                  });
              },
              error: err => {
                console.error(
                  'Error al terminar el processo del candidato',
                  err
                );
              },
            });
        }
      });
    });
  }

  abortLinkedinScraping(): Observable<string> {
    const url = this.urlAbortScraping;
    return this.httpClient.post<string>(url, {});
  }

  uploadCandidateByLinkedinUrl(
    linkedin: string
  ): Observable<ScrappingResponse> {
    const url = this.urlUploadCandidateByLinekdin + linkedin;
    return this.httpClient.get<ScrappingResponse>(url);
  }

  checkEmail(email: string): Observable<boolean> {
    const url = this.getCheckEmail + email;
    return this.httpClient.get<boolean>(url);
  }

  updateScoreKnowledge(
    candidateId: number,
    technologyId: number,
    score: number
  ): Observable<boolean> {
    return this.httpClient.put<boolean>(
      this.urlUpdateScoreKnowledge + `${candidateId}/${technologyId}/${score}`,
      {}
    );
  }

  uploadCognitiveAbilitiesScore(cognitiveAbilities: CognitiveScore) {
    const { candidateId, ...others } = cognitiveAbilities;
    this.updateRadarScore(
      'cognitive',
      this.getScoreMedia(Object.values(others))
    );
    const url = `${this.urlCognitiveAbilitiesScore}`;
    return this.httpClient.put(url, cognitiveAbilities);
  }
  uploadMentalityAbilitiesScore(mentalityAbilities: MentalityScore) {
    const { candidateId, ...others } = mentalityAbilities;
    this.updateRadarScore(
      'mentality',
      this.getScoreMedia(Object.values(others))
    );
    const url = `${this.urlMentalityAbilitiesScore}`;
    return this.httpClient.put(url, mentalityAbilities);
  }

  getCognitiveAbilitiesScore(candidateId: number): Observable<CognitiveScore> {
    const url = `${this.urlFindCognitiveAbilitiesScore}/${candidateId}`;
    return this.httpClient.get<CognitiveScore>(url).pipe(
        tap(({ candidateId, ...others }) =>
          this.updateRadarScore(
            'cognitive',
            this.getScoreMedia(Object.values(others)))));
  }
  getMentalityAbilitiesScore(candidateId: number): Observable<MentalityScore> {
    const url = `${this.urlGetMentalityAbilitiesScore}/${candidateId}`;
    return this.httpClient.get<MentalityScore>(url).pipe(
        tap(({ candidateId, ...others }) =>
          this.updateRadarScore(
            'mentality',
            this.getScoreMedia(Object.values(others)))));
  }

  getScoreMedia(numbers: number[]):number {
    let score = numbers.reduce((acc, num) => acc + num, 0) / numbers.length;
    return score;
  }

  getRadarScores(): Observable<RadarScores> {
    return this.radarScores.asObservable();
  }

  updateRadarScore(key: TypeOfRadar, value: number): void {
    let previous: RadarScores = this.radarScores.value;
    previous[key] = parseFloat(value.toFixed(1));
    this.radarScores.next(previous);
  }

  getCandidateByVacantByIds(
    candidateId: number,
    vacantId: number
  ): Observable<CandidateByVacantDto> {
    const url = `${this.urlGetCandidateByVacantByIds}${candidateId}/${vacantId}`;
    return this.httpClient.get<CandidateByVacantDto>(url);
  }

  getFollowers(idCandidateByVacant: number): Observable<Recruiter[]> {
    const url = `${this.urlGetFollowers}${idCandidateByVacant}`;
    return this.httpClient.get<Recruiter[]>(url);
  }

  deleteFollower(
    candidateByVacantId: number,
    followerId: number
  ): Observable<string> {
    const url = `${this.urlDeleteFollower}${candidateByVacantId}/${followerId}`;
    return this.httpClient.delete<string>(url, {});
  }

  getContactCardMatDialog(
    candidate: CandidateDto,
    vacantID: number,
    stage?: number
  ): MatDialogRef<ContactCardComponent> {
    return this._matDialog.open(ContactCardComponent, {
      disableClose: true,
      panelClass: 'mat-dialog-candidate',
      data: {
        candidateData: candidate,
        vacantId: vacantID,
        stage: stage,
      },
    });
  }

  editCandidate(IDcandidate: number, data: CandidateUpdateDto) {
    const url = `${this.urlEditCandidate}${IDcandidate}`;
    return this.httpClient.put<CandidateUpdateDto>(url, data);
  }

  getWorkPositions(): Observable<string[]> {
    const url = this.findAllWorkExperiencesPositions;
    return this.httpClient.get<string[]>(url);
  }
}
