import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  SocialAuthService,
  SocialUser,
  GoogleLoginProvider,
} from 'angularx-social-login';
import { environment } from '../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  googleLoginOptions = {
    scope: 'profile email openid https://www.googleapis.com/auth/calendar',
  };
  userMobySubscriber: BehaviorSubject<any>;
  userGeneral: BehaviorSubject<SocialUser>;
  errorLogin: BehaviorSubject<boolean>;
  errorStatus: BehaviorSubject<number>;
  isGoogle!: boolean;

  private baseUrl: string = environment.url.protocol + environment.url.url;
  private obtainAppToken: string =
    this.baseUrl + environment.services.auth.obtainAppToken;
  private findByEmailUrl: string =
    this.baseUrl + environment.services.auth.findByEmail;

  public user!: SocialUser;
  public userMoby!: string;
  public appToken!: string;
  public userIdRefreshToken!: string;
  apiKeyCalendar: string = environment.services.auth.apiKeyCalendar;

  constructor(
    private http: HttpClient,
    private authGoogle: SocialAuthService,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {
    this.userMobySubscriber = new BehaviorSubject({});
    this.errorLogin = new BehaviorSubject<boolean>(false);
    this.errorStatus = new BehaviorSubject<number>(null!);
    this.userGeneral = new BehaviorSubject<SocialUser>(null!);
    this._readToken();
  }

  login(): Promise<any> {
    return this.authGoogle
      .signIn(GoogleLoginProvider.PROVIDER_ID, this.googleLoginOptions)
      .then(() => {
        this.userGoogle();
      });
  }

  userGoogle(): void {
    this.authGoogle.authState.subscribe(userData => {
      if (userData) {
        if (this.validateEmail(userData.email)) {
          this.user = userData;
          this.getAppToken(this.user.idToken, this.user.authToken)
            .then(() => {
              this.isGoogle = true;
              this.userGeneral.next(userData);
              this.errorLogin.next(false);
              localStorage.setItem('user', JSON.stringify(userData));
            })
            .catch(err => {
              console.log('Hubo un error en la promesa del auth service', err);
            });
        } else {
          this.logout();
          this._snackBar.open(
            'Debes loguearte con un mail de Moby Digital',
            'Cerrar',
            {
              duration: 10000,
            }
          );
        }
      }
    });
  }

  logout(): void {
    if (this.user) {
      this.authGoogle.signOut();
    }
    this.userGeneral.next(null!);
    this.userMobySubscriber.next(null);
    this.router.navigateByUrl('/');
    localStorage.removeItem('userIdToken');
    localStorage.removeItem('userIdRefreshToken');
  }

  validateEmail = (email: string): boolean => {
    const emailMoby = /^[\w-\.]+@mobydigital.com/;
    return emailMoby.test(email.toLowerCase());
  };

  validateEmailExists(value: string): Observable<boolean> {
    const url = `${this.findByEmailUrl}/${value}`;
    return this.http.get<boolean>(url);
  }

  async getAppToken(idToken: string, authToken: string): Promise<void> {
    const headers = new HttpHeaders({
      authorization: this.addBearer(idToken),
      authtoken: authToken,
    });

    this.http
      .post(`${this.obtainAppToken}`, {}, { headers })
      .subscribe((data: any) => {
        this.userMoby = data;
        this.appToken = data.appToken;
        this.userIdRefreshToken = data.refreshToken;
        localStorage.setItem('userIdToken', this.appToken);
        localStorage.setItem('userIdRefreshToken', this.userIdRefreshToken);
        this.router.navigateByUrl('/dashboard');
      });
  }

  private _readToken(): string {
    const refreshToken = localStorage.getItem('userIdRefreshToken');
    if (refreshToken) {
      this.userIdRefreshToken = refreshToken;
    }
    return this.userIdRefreshToken;
  }

  isAuthenticated(): boolean {
    this._readToken();
    const helper = new JwtHelperService();
    const isExpired = helper.isTokenExpired(
      `Bearer ${this.userIdRefreshToken}`
    );
    if (isExpired) {
      localStorage.removeItem('userIdToken');
      localStorage.removeItem('userIdRefreshToken');
    }
    return !isExpired;
  }

  isAuthenticatedGuard(): boolean {
    if (this.isAuthenticated() && localStorage.getItem('userIdToken')) {
      return true;
    }
    this.router.navigateByUrl('/login');
    return false;
  }

  addBearer(token: string): string {
    return token.includes('Bearer ') ? token : `Bearer ${token}`;
  }
}
