import { Component, OnDestroy, OnInit } from '@angular/core';
import { SocialUser } from 'angularx-social-login';
import { AuthService } from 'app/services/auth.service';
import { ThemeService } from 'app/theme/theme.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  sidebarOpen = false;
  user!: SocialUser;
  subscription: Subscription[] = [];
  constructor(private auth: AuthService, private themeService: ThemeService) {}
  ngOnInit(): void {
    this.subscription.push(
      this.auth.userGeneral.subscribe(user => {
        this.user = user;
      })
    );
  }
  ngOnDestroy(): void {
    this.subscription.forEach(s => s.unsubscribe());
  }

  openCloseSidebar(): void {
    this.sidebarOpen = !this.sidebarOpen;
  }

  get dark() {
    return this.themeService.theme === 'dark';
  }

  set dark(enabled: boolean) {
    this.themeService.theme = enabled ? 'dark' : 'light';
  }
}
