import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';

// Servicios

// Material Imports
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';

import {
  SocialLoginModule,
  SocialAuthServiceConfig,
  GoogleLoginProvider,
} from 'angularx-social-login';

import { AppComponent } from 'app/app.component';
import { AppRoutingModule } from './app.router.module';
import { LocalStorageService } from './services/local-storage.service';
import { AuthService } from './services/auth.service';
import { AuthTokenInterceptor } from './services/interceptors/auth-token.interceptor';
import { DashboardService } from './services/dashboard.service';
import { SharedModule } from './shared/shared.module';
import { NotificationsModule } from './shared/components/notifications/notifications.module';
import { CalendarInterceptor } from './services/interceptors/calendar.interceptor';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CandidatesService } from './services/candidates.service';

import localEs from '@angular/common/locales/es-AR';
import { registerLocaleData } from '@angular/common';
import { LoadingService } from './services/loading.service';
import { ThemeService } from './theme/theme.service';
registerLocaleData(localEs);

const GOOGLESERVICE_CONFIG = {
  provide: 'SocialAuthServiceConfig',
  useValue: {
    autoLogin: true,
    providers: [
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(environment.auth.clientID),
      },
    ],
    onError: err => {
      console.log(err);
    },
  } as SocialAuthServiceConfig,
};
@NgModule({
  declarations: [AppComponent],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CalendarInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es-AR' },
    ThemeService,
    LoadingService,
    DashboardService,
    CandidatesService,
    MatDialog,
    {
      provide: MatDialogRef,
      useValue: {},
    },
    AuthService,
    LocalStorageService,
    GOOGLESERVICE_CONFIG,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    RouterModule,
    DragDropModule,
    SocialLoginModule,
    SharedModule,
    NotificationsModule,
    MatSnackBarModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
