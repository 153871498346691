import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { selectListNotification } from '../../../../app/main/dashboard/state/selectors/sendNotification.selectors';
import { skip } from 'rxjs/operators';
import {
  notificationsClose,
  notificationsOpen,
} from './state/actions/notifications.actions';
import { selectOpenNotification } from './state/selectors/notifications.selectors';
import { AppNotificationState } from './state/notifications.state';
import { Notification } from 'app/models/interfaces/notification.interface';
@Component({
  selector: 'app-notifications-container',
  templateUrl: './notifications-container.component.html',
  styleUrls: ['./notifications-container.component.scss'],
})
export class NotificationsContainerComponent implements OnInit {
  isOpen!: boolean;
  notifications: Notification[] = [
    {
      recruiterName: 'Tomas Todola',
      action: 'add',
      stand: 'MEAN Developer',
      opportunity: 'ICBC',
      interviewedName: 'Maximiliano Barraza',
    },
  ];

  @ViewChild('notificationsContainer', { static: true })
  notificationContainer!: ElementRef<HTMLDivElement>;
  constructor(private store: Store<AppNotificationState>) {}

  ngOnInit() {
    this.store.select(selectOpenNotification).subscribe((data: boolean) => {
      this.isOpen = data;
    });
    // Calling the store to enter the notification object
    this.store
      .select(selectListNotification)
      .pipe(skip(1))
      .subscribe(data => {
        const notification = data;
        // The last element will go first
        this.notifications.unshift(notification[0]);
        const notificationIcon =
          this.notificationContainer.nativeElement.children[0];
        notificationIcon.classList.add('notification__icon--active');
      });
  }

  @HostListener('document:click', ['$event'])
  clickedOut(event: Event) {
    const element = event.target as HTMLElement;
    if (this.isOpen && element) {
      if (!this.notificationContainer.nativeElement.contains(element)) {
        this.handleCloseNotification();
      }
    }
  }

  // Close notification window by clicking on the close icon
  handleCloseNotification() {
    this.store.dispatch(notificationsClose());

    const notificationIcon =
      this.notificationContainer.nativeElement.children[0];

    notificationIcon.classList.contains('notification__icon--active') &&
      notificationIcon.classList.remove('notification__icon--active');
  }
  // Open notification window by clicking on the notifications icon
  handleOpenNotification(): void {
    !this.isOpen
      ? // we check if isOpen is in false to click open notifications
        this.store.dispatch(notificationsOpen())
      : // we check if isOpen is in true to close it when clicking the icon of itself
        this.handleCloseNotification();
  }
}
