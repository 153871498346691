import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button-close-modal',
  templateUrl: './button-close-modal.component.html',
  styleUrls: ['./button-close-modal.component.scss'],
})
export class ButtonCloseModalComponent {
  @Output() close = new EventEmitter<boolean>();

  closeModal() {
    this.close.emit(true);
  }
}
