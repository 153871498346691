import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {
  constructor(private auth: AuthService) {}
  canActivateChild(): boolean {
    return this.auth.isAuthenticatedGuard();
  }

  canActivate(): boolean {
    return this.auth.isAuthenticatedGuard();
  }
  canLoad(): boolean {
    return this.auth.isAuthenticatedGuard();
  }
}
