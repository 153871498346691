import { ListSvgInterface } from 'app/models/svg/interfaces/svgList.interface';

export const mobyLogoSvg: ListSvgInterface = {
  svg: `
      <g clip-path="url(#clip0_11375_98273)">
        <path d="M84.9622 5.52954C87.064 5.52954 88.8803 5.90793 90.4104 6.66472C91.9591 7.40391 93.1393 8.46868 93.9503 9.8591C94.78 11.2495 95.1948 12.8862 95.1948 14.7694C95.1948 16.6526 94.78 18.2894 93.9503 19.6797C93.1393 21.0525 91.9591 22.1085 90.4104 22.8477C88.8803 23.5692 87.064 23.93 84.9622 23.93H78.9609V5.52954H84.9622ZM84.9622 21.9501C87.4512 21.9501 89.3505 21.3253 90.6593 20.0757C91.9688 18.8085 92.6228 17.0398 92.6228 14.7694C92.6228 12.4814 91.9591 10.6951 90.6316 9.41025C89.3228 8.12551 87.4326 7.48311 84.9622 7.48311H81.4776V21.9501H84.9622Z" fill="#010318"/>
        <path d="M98.3855 5.52954V23.93H95.8965V5.52954H98.3855Z" fill="#010318"/>
        <path d="M114.321 10.9643C113.797 9.91187 113.038 9.10092 112.044 8.53161C111.05 7.94498 109.893 7.65168 108.574 7.65168C107.255 7.65168 106.062 7.94498 104.996 8.53161C103.948 9.10092 103.116 9.92909 102.502 11.0161C101.905 12.0857 101.607 13.328 101.607 14.7428C101.607 16.1575 101.905 17.3997 102.502 18.4694C103.116 19.5391 103.948 20.3672 104.996 20.9539C106.062 21.5232 107.255 21.8079 108.574 21.8079C110.417 21.8079 111.935 21.2817 113.128 20.2292C114.321 19.1768 115.016 17.7534 115.215 15.9591H107.679V14.044H117.845V15.8556C117.7 17.3394 117.212 18.7023 116.381 19.9446C115.549 21.1696 114.456 22.1444 113.101 22.869C111.745 23.5764 110.237 23.93 108.574 23.93C106.821 23.93 105.221 23.5419 103.776 22.7655C102.33 21.9718 101.182 20.8763 100.333 19.4787C99.5021 18.0812 99.0859 16.5025 99.0859 14.7428C99.0859 12.9829 99.5021 11.4042 100.333 10.0067C101.182 8.59197 102.33 7.4964 103.776 6.72C105.221 5.92636 106.821 5.52954 108.574 5.52954C110.58 5.52954 112.351 6.004 113.887 6.95293C115.442 7.90185 116.571 9.23899 117.276 10.9643H114.321Z" fill="#010318"/>
        <path d="M121.036 5.52954V23.93H118.547V5.52954H121.036Z" fill="#010318"/>
        <path d="M134.852 5.52954V7.48311H129.573V23.93H127.045V7.48311H121.738V5.52954H134.852Z" fill="#010318"/>
        <path d="M143.056 19.8145H134.571L133.008 23.93H130.328L137.362 5.52954H140.292L147.298 23.93H144.619L143.056 19.8145ZM142.33 17.8496L138.814 8.50338L135.296 17.8496H142.33Z" fill="#010318"/>
        <path d="M150.515 21.9765H157.256V23.93H148V5.52954H150.515V21.9765Z" fill="#010318"/>
        <path d="M23.2115 5.52954V23.93H17.1836V14.7558L14.2106 23.93H9.02819L6.05517 14.7558V23.93H0V5.52954H7.41898L11.6739 16.575L15.8198 5.52954H23.2115Z" fill="#010318"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M37.4329 16.7422C37.0234 16.7422 36.6912 16.6365 36.6912 16.5059C36.6912 16.3755 37.0234 16.2697 37.4329 16.2697C37.8425 16.2697 38.1747 16.3755 38.1747 16.5059C38.1747 16.6365 37.8425 16.7422 37.4329 16.7422ZM42.6258 14.4886C41.3244 13.9056 39.2813 14.3314 38.1707 14.6011C33.6338 15.7034 27.0256 14.4886 25.9538 9.50687C25.8897 9.21852 25.8762 8.46701 26.1903 8.36713C26.3719 8.30769 29.121 9.69112 29.3932 9.00623C29.543 8.62724 27.5752 7.49473 27.4346 7.40323C27.4346 7.40323 27.3775 7.29287 27.4518 7.2304C27.9842 6.78903 30.0921 4.98306 29.1771 4.9331C25.7454 4.74263 23.514 9.43496 23.1869 13.6091C23.1869 13.6091 22.9909 17.4345 24.242 19.1779C25.6893 21.8791 28.5407 23.9016 32.6795 24.7134C34.712 25.1131 36.682 24.9134 38.1115 24.5574C41.0632 23.8266 42.9926 21.9569 43.8435 19.2112C44.2071 18.0382 44.4848 15.3215 42.6258 14.4886Z" fill="#010318"/>
        <path d="M57.0449 14.4959C58.2093 14.7558 59.1098 15.2929 59.7466 16.1073C60.4016 16.9042 60.7291 17.8312 60.7291 18.8881C60.7291 20.4994 60.1651 21.7469 59.0371 22.6306C57.9273 23.4969 56.3263 23.93 54.234 23.93H44.1094V5.52954H53.9611C55.926 5.52954 57.4725 5.93671 58.6004 6.75104C59.7284 7.56537 60.2924 8.75221 60.2924 10.3116C60.2924 11.3858 59.9922 12.2955 59.3918 13.0405C58.8097 13.7682 58.0273 14.2533 57.0449 14.4959ZM50.1678 12.7026H52.5147C53.0605 12.7026 53.4608 12.5986 53.7155 12.3908C53.9884 12.1828 54.1248 11.8623 54.1248 11.4292C54.1248 10.9787 53.9884 10.6495 53.7155 10.4415C53.4608 10.2163 53.0605 10.1036 52.5147 10.1036H50.1678V12.7026ZM52.9241 19.3039C53.4699 19.3039 53.8701 19.2086 54.1248 19.0181C54.3978 18.8101 54.5342 18.4809 54.5342 18.0304C54.5342 17.1468 53.9975 16.705 52.9241 16.705H50.1678V19.3039H52.9241Z" fill="#010318"/>
        <path d="M78.1536 5.52954L71.1629 18.4588V24.0973H65.053V18.4588L58.0625 5.52954H65.053L68.163 12.1122L71.2728 5.52954H78.1536Z" fill="#010318"/>
      </g>
      <defs>
        <clipPath id="clip0_11375_98273">
          <rect width="157.292" height="25" fill="white"/>
        </clipPath>
      </defs>
`,
  viewBox: '0 0 158 25',
};
