<svg
  width="5"
  height="8"
  viewBox="0 0 5 8"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M1.01255 1.55269L3.40735 3.94193L1.01812 6.33117C0.926876 6.43771 0.879199 6.57476 0.884613 6.71492C0.890027 6.85509 0.948133 6.98805 1.04732 7.08724C1.14651 7.18642 1.27947 7.24453 1.41963 7.24994C1.5598 7.25536 1.69685 7.20768 1.80339 7.11644L4.58805 4.33178C4.69178 4.22743 4.75 4.08628 4.75 3.93914C4.75 3.79201 4.69178 3.65085 4.58805 3.54651L1.80339 0.761848C1.69685 0.670608 1.5598 0.622931 1.41963 0.628345C1.27947 0.633759 1.14651 0.691865 1.04732 0.791052C0.948133 0.890238 0.890027 1.0232 0.884613 1.16337C0.879199 1.30353 0.926876 1.44058 1.01812 1.54712L1.01255 1.55269Z"
    fill="white" />
</svg>
<svg
  width="5"
  height="8"
  viewBox="0 0 5 8"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M1.01255 1.55269L3.40735 3.94193L1.01812 6.33117C0.926876 6.43771 0.879199 6.57476 0.884613 6.71492C0.890027 6.85509 0.948133 6.98805 1.04732 7.08724C1.14651 7.18642 1.27947 7.24453 1.41963 7.24994C1.5598 7.25536 1.69685 7.20768 1.80339 7.11644L4.58805 4.33178C4.69178 4.22743 4.75 4.08628 4.75 3.93914C4.75 3.79201 4.69178 3.65085 4.58805 3.54651L1.80339 0.761848C1.69685 0.670608 1.5598 0.622931 1.41963 0.628345C1.27947 0.633759 1.14651 0.691865 1.04732 0.791052C0.948133 0.890238 0.890027 1.0232 0.884613 1.16337C0.879199 1.30353 0.926876 1.44058 1.01812 1.54712L1.01255 1.55269Z"
    fill="white" />
</svg>
