import { Component, Input } from '@angular/core';
import { SendNotificationModel } from '../../../main/dashboard/state/model/sendNotification.interface';

@Component({
  selector: 'app-notifications-card',
  templateUrl: './notification-card-component.html',
  styleUrls: ['./notification-card.component.scss'],
})
export class NotificationsCardComponent {
  isOpenAccordionEmprise!: boolean;
  @Input() notification!: SendNotificationModel;
  isRead!: boolean;
  // temporal
  photo: string;
  constructor() {
    const { photoUrl } = JSON.parse(localStorage.getItem('user')!);
    this.photo = photoUrl;
  }

  // temporal

  handleOpenAccordion() {
    this.isOpenAccordionEmprise = !this.isOpenAccordionEmprise ? true : false;
  }

  checkIsRead() {
    this.isRead = !this.isRead ? true : false;
  }
}
