import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { TypeClass } from 'app/models/typeClass.enum';
import { FilesService } from 'app/services/files.service';

@Directive({
  selector: '[errorImg]',
})
export class CustomImgDirective implements OnInit {
  @Input() identificador!: number;
  @Input() valueImg!: string;
  errorImgUrl: string =
    'https://lh3.googleusercontent.com/pw/AM-JKLV2nnNh-bacAlD3vuJGJKO0F4yFy_CR1V3OXpvjXpqf68Wjd4n9X0CNEPOhRnBklaeIL87PkMP0K-ZNEN1mbqgE-NircRxrpC3PxyA7mcLu8b0eq0m4LVMfhIfg-NpeyTdP2_8uvelZpQYdkB5E1xo';

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private filesService: FilesService
  ) {}

  ngOnInit(): void {
    this.error();
  }

  error(): void {
    if (!this.valueImg || this.valueImg === this.errorImgUrl) {
      this.setDefaultImg();
      this.filesService
        .getFile(this.identificador, TypeClass.candidate, 'jpg')
        .subscribe({
          next: ({ message }) => {
            this.renderer.setProperty(
              this.el.nativeElement,
              'src',
              'data:image/jpeg;base64,' + message
            );
          },
        });
    }
  }

  setDefaultImg(): void {
    this.renderer.setProperty(
      this.el.nativeElement,
      'src',
      '../../assets/images/avatars/profile.jpg'
    );
  }
}
