import { Component, OnDestroy, OnInit } from '@angular/core';
import { SocialUser } from 'angularx-social-login';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  user!: SocialUser;
  loggedIn!: boolean;
  rightNavbar!: boolean;
  hiddenNavbar!: boolean;
  userName!: string;
  allSuscriptions: Subscription[] = [];

  constructor(private auth: AuthService) {}

  ngOnInit(): void {
    this.setUser();
  }
  ngOnDestroy(): void {
    this.allSuscriptions.forEach(suscription => suscription.unsubscribe());
  }

  // LOGOUT
  logOut(): void {
    this.auth.logout();
  }
  
  setUser(): void{
    const storedUser: SocialUser = JSON.parse(localStorage.getItem('user')!);
    
    this.allSuscriptions.push(
        this.auth.userGeneral.subscribe(user => {
          this.user = user;
          this.loggedIn = user != null;
        })
      );
      if (!this.user?.name && storedUser) {
        this.auth.userGeneral.next(storedUser);
      }
  }
}
