import { circleSvg } from 'assets/svg/circleSvg';
import { companySvg } from 'assets/svg/companySvg';
import { educationSvg } from 'assets/svg/educationSvg';
import { emailSvg } from 'assets/svg/emailSvg';
import { linkedinSvg } from 'assets/svg/linkedinSvg';
import { mobyLogoSvg } from 'assets/svg/mobyLogoSvg';
import { SvgInterface } from './interfaces/svg.interface';

export class Svg {
  listSvg: SvgInterface = {
    email: emailSvg,
    circle: circleSvg,
    linkedin: linkedinSvg,
    mobyLogo: mobyLogoSvg,
    company: companySvg,
    education: educationSvg,
  };

  figure: string;
  width: string;
  height: string;
  style: string;
  fill: string;

  svg!: string;

  constructor(
    figure: string,
    width: string,
    height: string,
    style: string,
    fill: string
  ) {
    this.figure = figure;
    this.width = width;
    this.height = height;
    this.style = style;
    this.fill = fill;
  }

  generateSvg() {
    this.svg = `
      <svg
        style="${this.style}"
        width="${this.width}"
        height="${this.height}"
        viewBox="${this.listSvg[this.figure as keyof SvgInterface].viewBox}"
        fill="${!this.fill ? 'none' : '${fill}'}"
        xmlns="http://www.w3.org/2000/svg"
        >
        ${this.listSvg[this.figure as keyof SvgInterface].svg}
      </svg>
      `;
    return this.svg;
  }
}
