import { ListSvgInterface } from 'app/models/svg/interfaces/svgList.interface';

export const linkedinSvg: ListSvgInterface = {
  svg: `
    <path
    d="M2.72413 3.57786H0.152084V10.9998H2.72413V3.57786Z"
    fill="#010318"
    />
    <path
    d="M2.89357 1.28199C2.87688 0.554269 2.33503 0 1.45511 0C0.575235 0 0 0.554269 0 1.28199C0 1.99462 0.558257 2.56484 1.42172 2.56484H1.43818C2.33503 2.56484 2.89357 1.99462 2.89357 1.28199Z"
    fill="#010318"
    />
    <path
    d="M12 6.74429C12 4.46463 10.7311 3.4036 9.03856 3.4036C7.67297 3.4036 7.06161 4.12395 6.72022 4.62931V3.57809H4.14784C4.18175 4.27451 4.14784 11 4.14784 11H6.72022V6.85497C6.72022 6.63315 6.73692 6.41188 6.80492 6.25306C6.99082 5.80993 7.41406 5.35112 8.12464 5.35112C9.05573 5.35112 9.42795 6.0317 9.42795 7.02905V10.9998H11.9999L12 6.74429Z"
    fill="#010318"
    />
    `,
  viewBox: '0 0 12 11',
};
