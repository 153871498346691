import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { LoadingService } from './services/loading.service';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isloading: boolean = false;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _platform: Platform,
    private loadingService: LoadingService
  ) {
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }
  }
  ngOnInit(): void {
    this.loading();
  }

  loading() {
    this.loadingService.loading.subscribe(value => {
      this.isloading = value;
    });
  }
}
