import { Injectable } from '@angular/core';

@Injectable()
export class ThemeService {
  constructor() {
    this.setTheme();
  }

  setTheme(): void {
    const currentTheme = localStorage.getItem('theme');
    if (!currentTheme) {
      this.theme = 'light';
    } else {
      this.theme = currentTheme;
    }
  }

  get theme(): string {
    return document.documentElement.getAttribute('theme')!;
  }

  set theme(name: string) {
    document.documentElement.setAttribute('theme', name);
    localStorage.setItem('theme', name);
  }
}
