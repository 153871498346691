import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomTooltipDirective } from './custom-tooltip.directive';
import { CustomImgDirective } from './customImg.directive';
import { DefaultImgDirective } from './defaultImg.directive';

@NgModule({
  declarations: [
    CustomImgDirective,
    DefaultImgDirective,
    CustomTooltipDirective,
  ],
  imports: [CommonModule],
  exports: [CustomImgDirective, DefaultImgDirective, CustomTooltipDirective],
})
export class DirectivesModule {}
