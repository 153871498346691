import { createSelector } from '@ngrx/store';
import { NotificationWindowState } from '../../model/notificationWindow.state';
import { AppNotificationState } from '../notifications.state';

export const selectNotificationWindowFeature = (state: AppNotificationState) =>
  state.windowNotification;

export const selectOpenNotification = createSelector(
  selectNotificationWindowFeature,
  (state: NotificationWindowState) => state.isOpen
);
export const selectCloseNotification = createSelector(
  selectNotificationWindowFeature,
  (state: NotificationWindowState) => state.isOpen
);
