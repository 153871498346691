<section
  class="notification__container-info"
  [ngClass]="{ 'notification__container-info--checked': isRead }">
  <div class="form__notification">
    <input
      (click)="checkIsRead()"
      type="radio"
      name="radio"
      [ngClass]="{ 'input__checked-notification': isRead }" />
    <div class="container__info-opportunities">
      <div class="flex__opportunities-container">
        <div class="container__info-emprise">
          <div class="container__name-accordion">
            <span>{{ notification.interviewedName }}</span>
            <mat-icon class="accordion-name" (click)="handleOpenAccordion()"
              >keyboard_arrow_down</mat-icon
            >
          </div>
          <div
            class="container__info-accordion"
            [ngClass]="{
              'container__info-accordion--active': isOpenAccordionEmprise
            }">
            <span title="{{ notification.opportunity }}">{{
              notification.opportunity
            }}</span>
            <p title="{{ notification.stand }}">{{ notification.stand }}</p>
          </div>
        </div>
        <div class="container__info-actions">
          <div class="info__name">
            <img alt="{{ notification.recruiterName }}" [src]="photo" />
            <span>{{ notification.recruiterName }}</span>
          </div>
          <ng-container [ngSwitch]="notification.action">
            <div class="info__action" *ngSwitchCase="'add'">
              <div class="info__action-person">
                <mat-icon>person_add</mat-icon>
                <span
                  title="{{ notification.recruiterName }} añadió a {{
                    notification.interviewedName
                  }} una entrevista"
                  >Te añadió a una entrevista</span
                >
              </div>
              <span>ayer a las 15:38</span>
            </div>
            <div class="info__action" *ngSwitchCase="'delete'">
              <div class="info__action-person">
                <mat-icon>delete_outline</mat-icon>
                <span
                  title="{{ notification.recruiterName }} eliminó al candidato"
                  >Eliminó al candidato</span
                >
              </div>
              <span>ayer a las 15:38</span>
            </div>
            <div class="info__action" *ngSwitchCase="'move'">
              <div class="info__action-person">
                <mat-icon>keyboard_arrow_right</mat-icon>
                <ng-container [ngSwitch]="notification.stage">
                  <span
                    *ngSwitchCase="'1'"
                    title="{{
                      notification.recruiterName
                    }}  movió al candidato a Búsqueda">
                    Movió al candidato a búsqueda
                  </span>
                  <span
                    *ngSwitchCase="'2'"
                    title="{{
                      notification.recruiterName
                    }}  movió al candidato a
                    Preselención">
                    Movió al candidato a preselención</span
                  >
                  <span
                    *ngSwitchCase="'3'"
                    title="{{
                      notification.recruiterName
                    }}  movió al candidato a
                    Entrevista">
                    Movió al candidato a entrevista</span
                  >
                  <span
                    *ngSwitchCase="'4'"
                    title="{{
                      notification.recruiterName
                    }}  movió al candidato a
                    Evaluación">
                    Movió al candidato a evaluación</span
                  >
                  <span
                    *ngSwitchCase="'5'"
                    title="{{
                      notification.recruiterName
                    }}  movió al candidato a
                    Presentación">
                    Movió al candidato a presentación</span
                  >
                  <span
                    *ngSwitchCase="'6'"
                    title="{{
                      notification.recruiterName
                    }}  movió al candidato a
                    Integración">
                    Movió al candidato a integración</span
                  >
                </ng-container>
              </div>
              <span>ayer a las 15:38</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>
