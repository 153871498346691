<mat-toolbar class="p-0">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <button
        mat-icon-button
        class="navbar-toggle-button"
        *ngIf="!hiddenNavbar && !rightNavbar"
        fxHide.gt-md>
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>
      <div
        class="toolbar-separator"
        *ngIf="!hiddenNavbar && !rightNavbar"
        fxHide.gt-md></div>
    </div>
    <div
      class="container__avatar-text"
      fxFlex="0 1 auto"
      fxLayout="row"
      fxLayoutAlign="start center">
      <app-notifications-container></app-notifications-container>
      <button
        mat-button
        color="red"
        [matMenuTriggerFor]="userMenu"
        class="user-button ripple">
        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="user?.name">
          <img
            errorImg
            [valueImg]="user.photoUrl"
            [src]="user.photoUrl"
            class="avatar mr-0 mr-sm-16"
            alt=""
            loading="lazy" />
          <div
            class="container__name"
            fxLayout="column"
            fxLayoutAlign="start"
            fxLayoutGap="5px">
            <span class="username mr-12 span-text" fxHide fxShow.gt-sm>{{
              user.name
            }}</span>
            <span class="rol mr-12" fxHide fxShow.gt-sm>Recursos Humanos</span>
          </div>
          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>
      </button>
      <mat-menu #userMenu="matMenu" id="mat-menu" [overlapTrigger]="false">
        <button mat-menu-item (click)="logOut()" class="ripple">
          <mat-icon class="icon__logout">exit_to_app</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>
