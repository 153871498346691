import { NgModule } from '@angular/core';
import { KeysPipe } from './keys.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { DateDifferencePipe } from './dateDifference.pipe';
import { LongLimit } from './longLimit.pipe';
import { FilterTechnology } from './filterTechnology.pipe';
import { ShowNameObject } from './showNameObject.pipe';
import { FirstLetterUpperCasePipe } from './first-letter-upper-case.pipe';
@NgModule({
  declarations: [
    KeysPipe,
    GetByIdPipe,
    HtmlToPlaintextPipe,
    CamelCaseToDashPipe,
    DateDifferencePipe,
    LongLimit,
    FilterTechnology,
    ShowNameObject,
    FirstLetterUpperCasePipe,
  ],
  imports: [],
  exports: [
    KeysPipe,
    GetByIdPipe,
    HtmlToPlaintextPipe,
    CamelCaseToDashPipe,
    DateDifferencePipe,
    FilterTechnology,
    LongLimit,
    ShowNameObject,
    FirstLetterUpperCasePipe,
  ],
})
export class PipesModule {}
