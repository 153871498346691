import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { ROOT_NOTIFICATION_REDUCERS } from '../notifications/state/notifications.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(ROOT_NOTIFICATION_REDUCERS),
    StoreDevtoolsModule.instrument({ name: 'Notifications' }),
    EffectsModule.forRoot([]),
  ],
})
export class NotificationsModule {}
