import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkeletonCandidateComponent } from './components/skeleton-candidate/skeleton-candidate.component';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import { SkeletonOportunityComponent } from './components/skeleton-oportunity/skeleton-oportunity.component';
import { MaterialModule } from '../material/material.module';
import { SkeletonVacantComponent } from './components/skeleton-vacant/skeleton-vacant.component';

@NgModule({
  declarations: [
    SkeletonCandidateComponent,
    SkeletonLoaderComponent,
    SkeletonOportunityComponent,
    SkeletonVacantComponent,
  ],
  imports: [CommonModule, MaterialModule],
  exports: [
    SkeletonCandidateComponent,
    SkeletonLoaderComponent,
    SkeletonOportunityComponent,
    SkeletonVacantComponent,
  ],
})
export class SkeletonModule {}
