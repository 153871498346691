<div class="div-notification" #notificationsContainer>
  <mat-icon
    (click)="handleOpenNotification()"
    class="notification__icon"
    id="notification"
    [ngClass]="{ 'notification__icon--active': notifications.length > 0 }"
    >notifications</mat-icon
  >
  <ng-container *ngIf="notifications.length > 0">
    <section
      class="section__notifications-container"
      [ngClass]="{ 'section__notifications-container--active': isOpen }">
      <header class="notifications__header">
        <div class="header">
          <h2 fxFlex="50">Notificaciones</h2>
          <app-button-close-modal
            fxFlex="50"
            (click)="handleCloseNotification()"
            fxLayoutAlign="flex-end none"></app-button-close-modal>
        </div>
        <section class="header__container-filters">
          <div class="container__filters-read">
            <span>Filtrar por no leídas</span>
            <span>Marcar todas como leídas</span>
          </div>
          <div class="container__opportunities-select">
            <div class="opportunities__select-filter">
              <div><p>Oportunidades:</p></div>
              <select
                title="Seleccionar oportunidades"
                class="select__opportunities"
                name="opportunities">
                <option value="Todas">Todas</option>
              </select>
            </div>
          </div>
        </section>
      </header>
      <main class="notifications__main">
        <form>
          <ng-container *ngFor="let notification of notifications">
            <app-notifications-card
              [notification]="notification"></app-notifications-card>
          </ng-container>
        </form>
      </main>
    </section>
  </ng-container>
</div>
