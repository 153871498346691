import { ActionReducerMap } from '@ngrx/store';
import { SendNotificationState } from '../../../../main/dashboard/state/model/sendNotification.state';
import { sendNotificationReducer } from '../../../../main/dashboard/state/reducers/sendNotification.reducers';
import { NotificationWindowState } from '../model/notificationWindow.state';
import { NotificationReducer } from './reducers/notifications.reducers';

export interface AppNotificationState {
  windowNotification: NotificationWindowState;
  notificationSend: SendNotificationState;
}

export const ROOT_NOTIFICATION_REDUCERS: ActionReducerMap<AppNotificationState> =
  {
    windowNotification: NotificationReducer,
    notificationSend: sendNotificationReducer,
  };
