import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CandidateDto, } from 'app/models/interfaces/candidateDto.interface';
import { CardCandidateService } from 'app/services/card-candidate.service';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-card',
  templateUrl: './candidate-card.component.html',
  styleUrls: ['./candidate-card.component.scss'],
})
export class ContactCardComponent implements OnDestroy {
  private subscriptions: Subscription[] = []
  constructor(
    private cardCandidateService: CardCandidateService,
    public card: MatDialogRef<ContactCardComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      candidateData: CandidateDto;
      vacantId: number;
      stage: number;
    }
  ) {
    this.cardCandidateService.setVacantId(this.data.vacantId);
    this.cardCandidateService.updateCandidateData(this.data.candidateData);
  }

  closeModal(): void {
    Swal.fire({
      icon: 'warning',
      title: '¿Desea guardar los cambios?',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then(result => {
      if (result.isConfirmed) {

        this.cardCandidateService.updateCandidate()
          .pipe(
            first()
          )
          .subscribe({
            error: () => {
              Swal.fire({
                icon: 'error',
                title: 'Error al editar la información del candidato!',
              });
            }
          })

      }
      this.card.close();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe())
  }
}
