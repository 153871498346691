import { createReducer, on } from '@ngrx/store';
import { sendNotification } from '../actions/sendNotification.actions';
import { SendNotificationState } from '../model/sendNotification.state';

export const initialState: SendNotificationState = { notification: [] };

export const sendNotificationReducer = createReducer(
  initialState,
  on(sendNotification, (state, { notification }) => {
    return { ...state, notification };
  })
);
