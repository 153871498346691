import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CandidateDto } from 'app/models/interfaces/candidateDto.interface';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CreatePdfService } from './create-pdf.service';

@Injectable({
  providedIn: 'root',
})
export class CardCandidateService {
  private candidateData: CandidateDto = {} as CandidateDto;
  private candidateChange$ = new Subject<boolean>();
  vacantId!: number;
  disabledPdf: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /* Urls */
  private baseUrl: string = environment.url.protocol + environment.url.url;
  private getCandidateToPdf: string =
    this.baseUrl + environment.services.candidates.getExportCandidateToPdf;
  private editCandidate: string =
    this.baseUrl + environment.services.candidates.editCandidate;

  constructor(private httpClient: HttpClient,
    private _createPdfService: CreatePdfService,
  ) {}

  /**
   * Assign the new values to candidateDto
   * @param Partial<CandidateDto>
   */
  public updateCandidateData(candidate: Partial<CandidateDto>): void {
    Object.assign(this.candidateData, candidate);
    this.candidateChange$.next(true);
  }

  /**
   * Get the requested values
   * @param keys required
   * @returns Pick<CandidateDto, any>;
   */
  public getCandidateData(keys: string[]) {
    const commonKeysObject: Pick<CandidateDto, any> = {};
    keys.forEach((key) => {
      if (!(key in this.candidateData)) throw new Error(`${key} do not exist in CandidateDto`)
      commonKeysObject[key] = this.candidateData[key as keyof CandidateDto];
    })
    return commonKeysObject;
  }

  public getVacantId(): number {
    return this.vacantId;
  }

  public setVacantId(id: number) {
    this.vacantId = id;
  }

  public subscribeChanges(): Observable<boolean> {
    return this.candidateChange$.asObservable()
  }
  /**
 * Gets through the url the pdf of the candidate
 * @param candidateId identification of the candidate
 * @param idVacant vacancy identification
 * @returns returns a request to the back of the requested pdf.
 */
  getCandidatePdf(): Observable<Blob> {
    const pdf = this._createPdfService.createPdf({ ...this.candidateData });
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    const url = this.getCandidateToPdf + this.candidateData.id;
    return this.httpClient.post<Blob>(url, pdf, httpOptions);
  }

  updateCandidate() {
    const url = `${this.editCandidate}${this.candidateData.id}`;
    return this.httpClient.put<CandidateDto>(url, this.candidateData);
  }

}
