<svg
  width="5"
  height="8"
  viewBox="0 0 5 8"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M4.62222 6.44731L2.22741 4.05807L4.61665 1.66883C4.70789 1.56229 4.75557 1.42524 4.75015 1.28508C4.74474 1.14491 4.68663 1.01195 4.58745 0.912764C4.48826 0.813577 4.3553 0.755472 4.21513 0.750058C4.07497 0.744644 3.93792 0.79232 3.83138 0.88356L1.04672 3.66822C0.942988 3.77257 0.884766 3.91372 0.884766 4.06086C0.884766 4.20799 0.942988 4.34915 1.04672 4.45349L3.83138 7.23815C3.93792 7.32939 4.07497 7.37707 4.21513 7.37165C4.3553 7.36624 4.48826 7.30813 4.58745 7.20895C4.68663 7.10976 4.74474 6.9768 4.75015 6.83663C4.75557 6.69647 4.70789 6.55942 4.61665 6.45288L4.62222 6.44731Z"
    fill="white" />
</svg>
<svg
  width="5"
  height="8"
  viewBox="0 0 5 8"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M4.62222 6.44731L2.22741 4.05807L4.61665 1.66883C4.70789 1.56229 4.75557 1.42524 4.75015 1.28508C4.74474 1.14491 4.68663 1.01195 4.58745 0.912764C4.48826 0.813577 4.3553 0.755472 4.21513 0.750058C4.07497 0.744644 3.93792 0.79232 3.83138 0.88356L1.04672 3.66822C0.942988 3.77257 0.884766 3.91372 0.884766 4.06086C0.884766 4.20799 0.942988 4.34915 1.04672 4.45349L3.83138 7.23815C3.93792 7.32939 4.07497 7.37707 4.21513 7.37165C4.3553 7.36624 4.48826 7.30813 4.58745 7.20895C4.68663 7.10976 4.74474 6.9768 4.75015 6.83663C4.75557 6.69647 4.70789 6.55942 4.61665 6.45288L4.62222 6.44731Z"
    fill="white" />
</svg>
