import { createReducer, on } from '@ngrx/store';
import {
  notificationsClose,
  notificationsOpen,
} from '../actions/notifications.actions';
import { NotificationWindowState } from '../../model/notificationWindow.state';

export const initialState: NotificationWindowState = { isOpen: false };

export const NotificationReducer = createReducer(
  initialState,
  on(notificationsOpen, () => ({ isOpen: true })),
  on(notificationsClose, () => ({ isOpen: false }))
);
