import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//Componentes

import { ButtonComponent } from './components/button/button.component';
import { ButtonCloseModalComponent } from './components/button-close-modal/button-close-modal.component';
import { CriticalSvgIconComponent } from './components/SVGs/criticalityArrows/critical-svg-icon/critical-svg-icon.component';
import { EditSvgIconComponent } from './components/SVGs/edit-svg-icon/edit-svg-icon.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LeftDobbleArrowSvgIconComponent } from './components/SVGs/left-dobble-arrow-svg-icon/left-dobble-arrow-svg-icon.component';
import { MajorSvgIconComponent } from './components/SVGs/criticalityArrows/major-svg-icon/major-svg-icon.component';
import { MenorSvgIconComponent } from './components/SVGs/criticalityArrows/menor-svg-icon/menor-svg-icon.component';
import { NotificationAlertComponent } from './components/notification-alert/notification-alert.component';
import { NotificationsCardComponent } from './components/notificationCard/notification-card.component';
import { NotificationsContainerComponent } from './components/notifications/notifications-container.component';
import { PdfLogicComponent } from './components/pdf-logic/pdf-logic.component';
import { RadioInputSkillComponent } from './components/radio-input-skill/radio-input-skill.component';
import { RatingBarComponent } from './components/rating-bar/rating-bar.component';
import { RigthDobbleArrowSvgIconComponent } from './components/SVGs/rigth-dobble-arrow-svg-icon/rigth-dobble-arrow-svg-icon.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { SpanErrorComponent } from './components/span-error/span-error.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { TrashSVGiconComponent } from './components/SVGs/trash-svgicon/trash-svgicon.component';
import { TrivialSvgIconComponent } from './components/SVGs/criticalityArrows/trivial-svg-icon/trivial-svg-icon.component';

// Modulos
import { DirectivesModule } from 'app/directives/directives.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './module/material/material.module';
import { PipesModule } from 'app/pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { SkeletonModule } from './module/skeleton/skeleton.module';

import { ThemeService } from 'app/theme/theme.service';

@NgModule({
  declarations: [
    ButtonCloseModalComponent,
    ButtonComponent,
    CriticalSvgIconComponent,
    EditSvgIconComponent,
    LayoutComponent,
    LeftDobbleArrowSvgIconComponent,
    MajorSvgIconComponent,
    MenorSvgIconComponent,
    NotificationAlertComponent,
    NotificationsCardComponent,
    NotificationsContainerComponent,
    PdfLogicComponent,
    RadioInputSkillComponent,
    RatingBarComponent,
    RigthDobbleArrowSvgIconComponent,
    SidebarComponent,
    SnackbarComponent,
    SpanErrorComponent,
    ToolbarComponent,
    TrashSVGiconComponent,
    TrivialSvgIconComponent,
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    FlexLayoutModule,
    FormsModule,
    MaterialModule,
    PipesModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    ButtonCloseModalComponent,
    ButtonComponent,
    CommonModule,
    CriticalSvgIconComponent,
    DirectivesModule,
    EditSvgIconComponent,
    FlexLayoutModule,
    FormsModule,
    LayoutComponent,
    MajorSvgIconComponent,
    MaterialModule,
    MenorSvgIconComponent,
    NotificationAlertComponent,
    PdfLogicComponent,
    PipesModule,
    RadioInputSkillComponent,
    RatingBarComponent,
    ReactiveFormsModule,
    SkeletonModule,
    SpanErrorComponent,
    TrashSVGiconComponent,
    TrivialSvgIconComponent,
  ],
  providers: [ThemeService],
})
export class SharedModule {}
