import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileSaveDto } from 'app/models/interfaces/fileSaveDto.interface';
import { GenericResponse } from 'app/models/interfaces/genericResponse';
import { TypeClass } from 'app/models/typeClass.enum';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  // Base URL
  private baseUrl: string = environment.url.protocol + environment.url.url;

  private urlUploadImg: string =
    this.baseUrl + environment.services.candidates.uploadImg;
  private urlUploadPdf: string =
    this.baseUrl + environment.services.candidates.uploadPdf;
  private urlGetFile: string =
    this.baseUrl + environment.services.candidates.getFile;
  private urlDeleteFile: string =
    this.baseUrl + environment.services.candidates.deleteFile;

  constructor(private httpClient: HttpClient) {}

  uploadImg(file: FileSaveDto): Observable<GenericResponse> {
    const url = this.urlUploadImg;
    return this.httpClient.post<GenericResponse>(url, file);
  }

  uploadPdf(file: FileSaveDto): Observable<GenericResponse> {
    const url = this.urlUploadPdf;
    return this.httpClient.post<GenericResponse>(url, file);
  }

  getFile(fileID: number, typeClass: TypeClass, fileType: string) {
    const params = new HttpParams()
      .set('id', fileID)
      .set('class', typeClass)
      .set('file extension', fileType);
    const url = this.urlGetFile;
    return this.httpClient.get<{ message: string }>(url, { params }).pipe(
        map((res)=>{
            if(res === null){
                        throw new Error(`No se encontró el archivo ${fileType} asociado a este candidato`)
            }
            return res;
        }),
    );
  }

  deleteFile(fileID: number, typeClass: TypeClass, fileType: string) {
    const params = new HttpParams()
      .set('id', fileID)
      .set('class', typeClass)
      .set('file extension', fileType);
    const url = this.urlDeleteFile;
    return this.httpClient.delete<{ message: string }>(url, { params });
  }

  // abrir PDF
  openFile(fileUrl: string) {
    return this.httpClient.get(fileUrl, {
      headers: new HttpHeaders({
        Accept: 'application/pdf',
      }),
      responseType: 'blob',
    });
  }
}
