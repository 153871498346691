import { Injectable } from '@angular/core';

type DateType = Date | string | null;

@Injectable({
  providedIn: 'root',
})
export class DateTimeService {
  /**
   * diferenciaAnios is used to show how much time has passed between two dates.
   * @param startDate : Is a date in string format (example, '5-10-2022'). Has to be minor than endDate.
   * @param endDate : Is a date in string format (example, '6-11-2023'). Has to be bigger or equal than startDate.
   * endDate can be the present day. To do that, endDate has to have the values 'Actualidad' or null.
   * @returns how much time has passed, in months and years (example, '- 1 año 2 meses')
   */
  yearsDifferenceBetweenDates(startDate: DateType, endDate: DateType): string {
    let start: string = this.parseDate(startDate);
    let end: string = this.parseDate(endDate);
    if (!endDate || this.areInvalidDates(startDate, endDate)) {
      end = this.parseDate(new Date().toISOString().slice(0, 10));
    }

    let years = this.getDifferenceInYears(start, end);
    let months = this.getDifferenceInMonths(start, end);
    years = this.calibrateYears(years, months);
    months = this.calibrateMonths(months);
    return years == 0 && months == 0
      ? ''
      : `${this.addYearSuffix(years)}${this.addMonthSuffix(months, years)}`;
  }

  /**
   * Used in diferenciaAnios(...) to have a valid format to work.
   * @param date must represent a date time(11/11/2021 21:53:01"), or present (null, "", "Actualidad").
   * @returns a date formatted to be used in diferenciaAnios(...)
   */
  private parseDate(date: DateType): string {
    if (date && typeof date === 'string') {
      return this.parseToCandiDate(date);
    } else {
      let start = String(date);
      return this.getFormattedDate(start);
    }
  }

  /**
   * Used when diferenciaAnios(...) receive some date with String Type
   * @param date can be "Actualidad", "", null, or some date in string format (11/11/2021 20:30:45).
   * @returns a converted date in string format (11-11-2021) or "Actualidad", "", null.
   */
  private parseToCandiDate(date: string): string {
    return date.length > 10 && date !== 'Actualidad'
      ? date.slice(0, 10).replace('/', '-')
      : date;
  }

  /**
   * Used when diferenciaAnios(...) receives some date with Date type.
   * @param stringDate must be in format of a date in string (for example, '2015-09-17 15:00:00').
   * @returns a string in format MM/dd/yyyy.
   */
  private getFormattedDate(stringDate: string): string {
    const date = new Date(stringDate);
    const year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
  }

  private calibrateYears(years: number, months: number): number {
    return months < 0 ? years - 1 : years;
  }

  private calibrateMonths(months: number): number {
    return months < 0 ? months + 12 : months;
  }

  private areInvalidDates(startDate: DateType, endDate: DateType) {
    return (
      !startDate ||
      !endDate ||
      (startDate > endDate && endDate !== 'Actualidad')
    );
  }

  private getYear(aDate: string | null): number {
    return aDate == 'Actualidad' || aDate == null
      ? new Date().getFullYear()
      : new Date(aDate).getFullYear();
  }

  private getMonth(aDate: string | null): number {
    return aDate == 'Actualidad' || aDate == null
      ? new Date().getMonth()
      : new Date(aDate).getMonth();
  }

  private getDifferenceInYears(
    startDate: string | null,
    endDate: string | null
  ) {
    return startDate !== null || startDate !== ''
      ? this.getYear(endDate) - this.getYear(startDate)
      : 0;
  }

  private getDifferenceInMonths(
    startDate: string | null,
    endDate: string | null
  ) {
    return startDate !== null || startDate !== ''
      ? this.getMonth(endDate) - this.getMonth(startDate)
      : 0;
  }

  private addYearSuffix(years: number): string {
    if (years > 1) {
      return `${years} años`;
    }
    if (years == 1) {
      return `${years} año`;
    }
    return '';
  }

  private addMonthSuffix(months: number, years: number): string {
    if (months > 1) {
      return years > 0 ? `, ${months} meses` : `${months} meses`;
    } else if (months == 1) {
      return years > 0 ? `, ${months} mes` : `${months} mes`;
    } else {
      return '';
    }
  }
}
